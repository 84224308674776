@import '../../../styles/variables';

.error{
    background-color: color(charcoal);
    width: 100vw;
    height: 100vh;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:  var(--mainSectionPadding);

    svg{
        @include mobile{
            height: 78px;
        }
    }

    .error__container{
        padding: 20px;
        max-width: 600px;

        @include mobile{
            padding-top: 0;
            padding-bottom: 60px;
        }
    }

    h1{
        margin: 0;
        font-size: 10rem;
        line-height: 0;

        @include mobile{
            font-size: 6rem;
        }
    }

    h2{
        margin: 30px 0;
    }

    .error__cta{
        color: color(rose);
        font-weight: 600;
        width: fit-content;
        border-bottom: 1px solid color(rose);
    }

    .error__cta:hover{
        color: color(blue);
        cursor: pointer;
        border-color: color(blue);
    }
}
